<template>
  <v-container
    fluid :style="'max-width: ' + tableWidth"
  >
    <div v-if="poll != null" class="text-left">
      <div v-if="poll.transparency != TRANSPARENCY.ANONYMOUS">
        <v-simple-table class="text-left">
          <template v-slot:default>
            <tbody class="text-center">
              <tr class="header-row">
                <td></td>
                <td v-for="(item, item_idx) in items" :key="item_idx" class="header-column">
                  <span class="header-column-text">
                    {{expandToEmoji(item.title)}}
                  </span>
                </td>
              </tr>
              <tr v-for="(voter, idx) in voters" :key="idx">
                <td class="text-left">
                  <div class="voter-chip">
                    <img class="user-icon mr-1" :src="voter.profile.image_32"/>
                    {{ voter.profile.real_name }}
                  </div>
                </td>
                <td v-for="(item_vote, item_vote_idx) in voter.item_votes" :key="item_vote_idx">
                  <v-icon v-if="item_vote" class="checkbox-icon-true">mdi-checkbox-marked-circle-outline</v-icon>
                  <v-icon v-if="!item_vote" class="checkbox-icon-false">mdi-checkbox-blank-circle-outline</v-icon>
                </td>
              </tr>
              <tr v-if="voters == null || voters.length == 0">
                <td>
                  No votes yet.
                </td>
              </tr> 
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div v-else>
        <p>
          Can't show Grid View for anonymous polls.
        </p>
        <p>
          Why? Grid view shows you who voted for each choice. With anonymous polls you can't know that.
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { POLL_TYPE_NAMES, POLL_TYPES, TRANSPARENCY } from '../../Constants.js'
import axios from 'axios'
import { Api } from '@/api/Api'
import { expandToEmoji } from '../../Emoji'

export default {
  name: 'PollViewGrid',
  components: {
  },
  props: {
    poll: Object
  },
  data () {
    return {
      POLL_TYPE_NAMES: POLL_TYPE_NAMES,
      POLL_TYPES: POLL_TYPES,
      TRANSPARENCY: TRANSPARENCY,
      expandToEmoji: expandToEmoji,
      totalVotes: null,
      showWhoVoted: true,
      showNames: true,
      items: null,
      voters: null

    }
  },
  computed: {
    tableWidth () {
      if (this.items == null) {
        return 0
      } else {
        let colWidth = 150
        let rowHeaderWidth = 400
        let minWidth = rowHeaderWidth + this.items.length * colWidth;
        return `${minWidth}px`
      }
    }
  },
  watch: {
    $route(to, from) {
    },
    poll(to, from) {
      this.setup()
    }
  },
  methods: {
    setup() {
      this.totalVotes = 0
      this.items = []
      this.voters = new Map()

      for (const item of this.poll.items) {
        this.items.push(item)
        for(const voter of item.voters) {
          this.voters.set(voter.id, voter)
        }
      }

      for(let [voter_id, voter] of this.voters) {
        voter.item_votes = []
        for (let i=0; i < this.items.length; i++) {
          voter.item_votes.push(false)
        }
      }

      for(let item_ind=0; item_ind < this.items.length; item_ind++) {
        let item = this.items[item_ind]
        for(const voter of item.voters) {
          this.voters.get(voter.id).item_votes[item_ind] = true
        }
      }
      var voters_array = new Array()

      for(let [voter_id, voter] of this.voters) { 
        voters_array.push(voter)
      }
      this.voters = voters_array
    }

  },
  mounted: function () {
    this.setup()
  },
  filters: {
  }
}
</script>

<style scoped>

</style>

<style scoped>

  .poll-title-link {
    color: inherit;
    text-decoration: none;
  }

  .poll-title-link:hover {
    color: var(--color-blue);
  }

  .upvote-icon {
    color: var(--color-votum-red)!important;
    font-size: 19px!important;
    opacity: 0.8;
  }

  .upvote-number {
    font-size: 18px!important;
    font-weight: bold;
    opacity: 0.9;
  }

  .item-title {
    font-size: 18px!important;
    font-weight: 500;
  }

  .v-progress-linear {
    border-radius: 3px;
  }

  .vote-percentage {
    font-size: 12px;
    font-weight: 600;
  }

  .voter-chip {
    display: inline-block;
    margin: 1px;
    padding: 2px 5px;
    /* background: rgb(245, 245, 245); */
    border-radius: 3px;
    font-size: 12px;
  }
  .voter-chip img {
    vertical-align: text-bottom;
  }

  .switches {
    margin: 0;
  }

  .switch-label {
    font-size: 14px!important;
  }

  .switches >>> .v-input--selection-controls__input {
    margin-right: 0;
  }

  hr {
    background-color: #e2e2e2
  }

  .header-row {
  }

  .header-column {
    max-width: 150px;
    vertical-align: top;
  }

  .header-column-text {
    /* writing-mode:vertical-rl;
    transform:rotate(180deg) perspective(0);

    height: 100%;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block; */

    /* bottom: 0;
    margin: 0 0 24px 12px;
    padding: 0;
    text-align: left;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    white-space: nowrap; */
  }

  .checkbox-icon-true {
    color: var(--color-blue)!important;
  }

  .checkbox-icon-false {
    color: var(--color-blue)!important;
    opacity: 0.3;
  }
</style>
