<template>
  <v-container
    fluid style="max-width: 1000px; width: 90%;"
  >
    <div class="text-left">
      <h1 class="mt-5">Your poll history</h1>    
      <div v-if="polls.length > 0">
        <v-simple-table class="text-left mt-10">
          <template v-slot:default>
            <tbody>
              <tr v-for="(poll, idx) in polls" :key="idx">
                <td style="width: 10px; padding-right: 0;">
                    <v-icon v-if="poll.poll_type == POLL_TYPES.QUICK_POLL" style="color: var(--color-yellow); display: inline;">mdi-lightning-bolt</v-icon>
                    <v-icon v-if="poll.poll_type == POLL_TYPES.OPEN_POLL" style="color: var(--color-light-blue);">mdi-lightbulb</v-icon>
                </td>
                <td style="width: 60%;" class="py-1">
                  <router-link :to="`show-poll/${ poll.id }`" class="poll-title-link">
                    {{ poll.title }}
                  </router-link>
                  </td>
                <td>{{ poll.create_time | moment('YYYY-MM-DD') }}</td>
                <td v-if="poll.slack_channel.channel_info != null" style="color: var(--color-blue);">
                  <a class="channel-url" :href="'slack://channel?team=' + poll.slack_channel.team_id + '&id=' + poll.slack_channel.channel_id">
                    {{ '#'+poll.slack_channel.channel_info.name }}
                  </a>
                </td>
                <td v-else></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div v-if="loaded && polls.length == 0" class="mt-10">
        You haven't created any polls yet. 🙁 
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { POLL_TYPE_NAMES, POLL_TYPES, TRANSPARENCY } from '../Constants.js'
import axios from 'axios'
import { Api } from '@/api/Api'

export default {
  name: 'PollHistory',
  components: {
  },
  props: {
  },
  data () {
    return {
      polls: [],
      loaded: false,
      POLL_TYPE_NAMES: POLL_TYPE_NAMES,
      POLL_TYPES: POLL_TYPES,
      TRANSPARENCY: TRANSPARENCY
    }
  },
  metaInfo () {
    return {
      title: `Poll History | Upvote`,
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    fetchPolls () {
      this.$store.state.appBarProgressActive = true
      return new Promise((resolve, reject) => {
        axios(
          Api.baseUrl + '/get-poll-history',
          {
            method: 'post',
            data: {},
            withCredentials: true
          }
        ).then((response) => {
          this.polls = response.data
          this.loaded = true
          resolve(response)
        }).catch((error) => {
          console.log(error)
          reject(error)
        }).finally(() => {
          this.$store.state.appBarProgressActive = false
        })
      })
    }
  },
  mounted: function () {
    this.fetchPolls()
  },
  filters: {
  }
}
</script>

<style scoped>

  .poll-title-link {
    color: inherit;
    text-decoration: none;
  }

  .poll-title-link:hover {
    color: var(--color-blue);
  }

  .channel-url { 
    text-decoration: none;
  }
</style>
