<template>
  <v-container
    fluid
  >
    <div v-if="poll != null" class="text-left">
      <v-row class="mt-5 mx-auto" style="max-width: 900px;">
        <v-col cols=12 md=12 class="text-center">
          <h2>{{ poll.title }}</h2>    
          <p>{{ poll.subtitle }}</p>    
        </v-col>
      </v-row>
      <div v-if="poll.series != null" style="max-width: 700px;" class="mx-auto mt-2">
        <v-slider
          v-model="sliderPollIdx"
          step="1"
          min="0"
          ticks="always"
          thumb="false"
          :tick-labels="sliderTickLabels"
          :max="poll.series.length - 1"
          @end="onSliderChange"
        >
        </v-slider>
      </div>
      <div v-else class="mx-auto text-center poll-date">
        <p>{{ poll.create_time |  moment("MMMM DD, YYYY") }}
          <span v-if="poll.slack_channel.channel_info != null">
            in
            <a class="channel-url" :href="'slack://channel?team=' + poll.slack_channel.team_id + '&id=' + poll.slack_channel.channel_id">
              {{ '#'+poll.slack_channel.channel_info.name }}
            </a>
          </span>
        </p>
      </div>
      <v-tabs class="mb-5" centered>
        <v-tab class="tabs" @click="viewMode='default'"><v-icon class="mr-1" style="font-size: 16px">mdi-poll</v-icon>Default</v-tab>
        <v-tab class="tabs" @click="viewMode='grid'"><v-icon class="mr-1" style="font-size: 16px">mdi-dots-grid</v-icon>Grid</v-tab>
        <v-tab class="tabs" @click="viewMode='pie'"><v-icon class="mr-1" style="font-size: 16px">mdi-chart-pie</v-icon>Pie</v-tab>
        <v-tab class="tabs" @click="viewMode='series'"><v-icon class="mr-1" style="font-size: 16px">mdi-chart-line</v-icon>Trend</v-tab>
      </v-tabs>
      <div class="mt-10" style="margin-bottom: 100px;">
        <PollViewDefault
          style="max-width: 900px;"
          v-if="poll != null && viewMode=='default'"
          :poll="poll"
        ></PollViewDefault>
        <PollViewGrid
          v-if="poll != null && viewMode=='grid'"
          :poll="poll"
        ></PollViewGrid>
        <PollViewPie
          v-if="poll != null && viewMode=='pie'"
          :poll="poll"
        ></PollViewPie>
        <PollViewSeries
          v-if="poll != null && viewMode=='series'"
          :poll="poll"
        ></PollViewSeries>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import { POLL_TYPE_NAMES, POLL_TYPES, TRANSPARENCY } from '../Constants.js'
import axios from 'axios'
import { Api } from '@/api/Api'
import { expandToEmoji } from '../Emoji'
import PollViewDefault from '../components/PollView/PollViewDefault.vue'
import PollViewGrid from '../components/PollView/PollViewGrid.vue'
import PollViewPie from '../components/PollView/PollViewPie.vue'
import PollViewSeries from '../components/PollView/PollViewSeries.vue'

export default {
  name: 'ShowPoll',
  components: {
    PollViewDefault,
    PollViewGrid,
    PollViewPie,
    PollViewSeries
  },
  props: {
  },
  data () {
    return {
      poll: null,
      poll_type_names: POLL_TYPE_NAMES,
      poll_types: POLL_TYPES,
      TRANSPARENCY: TRANSPARENCY,
      expandToEmoji: expandToEmoji,
      totalVotes: 0,
      showWhoVoted: true,
      showNames: true,
      viewMode: 'default',

      sliderPollIdx: null,
      sliderTickLabels: null
    }
  },
  metaInfo () {
    const title = this.poll != null ? this.poll.title : 'Upvote'
    return {
      title: `${title} | Upvote`,
    }
  },
  computed: {
  },
  watch: {
    $route(to, from) {
      this.fetchPoll()  
      this.updateSliderLabels()
    },
    sliderPollIdx(curr, prev) {
      this.updateSliderLabels()
    }
  },
  methods: {
    fetchPoll () {
      let requestData = {
        poll_id: this.$route.params.pollId
      }

      this.$store.state.appBarProgressActive = true
      return new Promise((resolve, reject) => {
        axios(
          Api.baseUrl + '/get-poll',
          {
            method: 'post',
            data: requestData,
            withCredentials: true
          }
        ).then((response) => {
          this.poll = response.data

          if (this.poll.series != null) {
            this.sliderPollIdx = 0
            this.sliderTickLabels = []
            for (const [idx, seriespoll] of this.poll.series.entries()){
              if (seriespoll.id == this.poll.id) {
                this.sliderPollIdx = idx
              }
            }
          }
          this.updateSliderLabels()

          resolve(response)
        }).catch((error) => {
          console.log(error)
          reject(error)
        }).finally(() => {
          this.$store.state.appBarProgressActive = false
        })
      })
    },
    updateSliderLabels() {
      if (this.poll.series != null) {
        this.sliderTickLabels = []
        for (const [idx, seriesPoll] of this.poll.series.entries()){
          if (idx === this.sliderPollIdx) {
            this.sliderTickLabels.push(moment(seriesPoll.create_time).format('MMM Do YYYY'))
          } else {
            this.sliderTickLabels.push('')
          }
        }
      }
    },
    onSliderChange() {
      this.$router.push({name: 'show-poll', params: {pollId: this.poll.series[this.sliderPollIdx].id}})
    }
  },
  mounted: function () {
    this.fetchPoll()
  },
  filters: {
  }
}
</script>

<style scoped>

</style>

<style scoped>

  hr {
    background-color: #e2e2e2
  }
  .tabs {
    text-transform: none;
  }
  .tabs-active {
    color: black!important;
  }

  .poll-date {
    font-size: 14px;
    color:#606060;  
  }

  .channel-url { 
    text-decoration: none;
  }
</style>
