import * as Util from './Util.js'
import * as ColorSchemes from '../../../ColorSchemes'
import { expandToEmoji } from '../../../Emoji'

const setupPercentByOption = function (self) {
  let data = {
    labels: [],
    datasets: []
  };  

  for (const [idx, item] of self.series[0].items.entries()) {
    data.datasets.push(
      {
        label: expandToEmoji(item.title),
        data: [],
        fill: idx == 0 ? 'origin' : '-1',
        backgroundColor: Util.addAlpha(ColorSchemes.ClassicMedium10[idx%10], 0.8),
        borderColor: Util.addAlpha(ColorSchemes.ClassicMedium10[idx%10], 0.8),
        borderWidth: 2,
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      }
    )
  }
  
  let maxY = 0
  for (const poll of self.series) {
    data.labels.push(poll.create_time)
    let total_votes = 0 
    for(const [idx, item] of poll.items.entries()) {
      total_votes += item.vote_count
    }
    for(const [idx, item] of poll.items.entries()) {
      if (total_votes > 0) {
        data.datasets[idx].data.push(item.vote_count / total_votes * 100)
        maxY = Math.max(maxY, item.vote_count / total_votes * 100)
      } else {
        data.datasets[idx].data.push(null)
      }
    }
  }

  let config = {
    type: 'line',
    data: data,
    options: {
      responsive: true,
      scales: {
          x: {
              type: 'time',
              time: {
                  unit: 'day'
              },
              grid: {
                display: false,
                drawBorder: true,
                drawOnChartArea: true,
                drawTicks: false,
              }
          },
          y: {
              display: true,
              stacked: true,
              title: {
                display: true,
                text: '% of Upvotes'
              },
              suggestedMin: 0,
              max: 100
          }
      },
      plugins: {
        legend: {
          position: 'bottom',
        },
      }
    },
  }
  return config
}

export { setupPercentByOption }
