<template>
  <v-container
    fluid style="max-width: 600px;"
  >
    <div v-if="poll != null" class="text-left">
      <canvas id="pie-div"></canvas>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { POLL_TYPE_NAMES, POLL_TYPES, TRANSPARENCY } from '../../Constants.js'
import axios from 'axios'
import { Api } from '@/api/Api'
import { expandToEmoji } from '../../Emoji'
import Chart from 'chart.js/auto'
import * as ColorSchemes from '../../ColorSchemes'
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  name: 'PollViewPie',
  components: {
  },
  props: {
    poll: Object
  },
  data () {
    return {
      POLL_TYPE_NAMES: POLL_TYPE_NAMES,
      POLL_TYPES: POLL_TYPES,
      TRANSPARENCY: TRANSPARENCY,
      expandToEmoji: expandToEmoji,
      totalVotes: null,

      chart: null
    }
  },
  computed: {
  },
  watch: {
    $route(to, from) {
    },
    poll(to, from) {
      this.setup()
    }
  },
  methods: {
    setup() {
      let ctx = document.getElementById('pie-div');
      let data = {
        labels: [],
        datasets: [
          {
            label: 'Upvotes',
            data: [],
            backgroundColor: ColorSchemes.ClassicMedium10
          }
        ]
      };  

      for (const item of this.poll.items) {
        data.labels.push(expandToEmoji(item.title))
        data.datasets[0].data.push(item.vote_count)
      }

      let config = {
        type: 'pie',
        data: data,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom',
            },
            title: {
              display: false,
              text: expandToEmoji(this.poll.title)
            },
          }
        },
      };
      if(this.chart != null) {
        this.chart.destroy()
      } 
      this.chart = new Chart(ctx, config);
    }
  },
  mounted: function () {
    this.setup()
  },
  filters: {
  }
}
</script>

<style scoped>

</style>

<style scoped>

  .poll-title-link {
    color: inherit;
    text-decoration: none;
  }

  .poll-title-link:hover {
    color: var(--color-blue);
  }

  .upvote-icon {
    color: var(--color-votum-red)!important;
    font-size: 19px!important;
    opacity: 0.8;
  }

  .upvote-number {
    font-size: 18px!important;
    font-weight: bold;
    opacity: 0.9;
  }

  .item-title {
    font-size: 18px!important;
    font-weight: 500;
  }

  .v-progress-linear {
    border-radius: 3px;
  }

  .vote-percentage {
    font-size: 12px;
    font-weight: 600;
  }

  .voter-chip {
    display: inline-block;
    margin: 1px;
    padding: 2px 5px;
    /* background: rgb(245, 245, 245); */
    border-radius: 3px;
    font-size: 12px;
  }
  .voter-chip img {
    vertical-align: text-bottom;
  }

  .switches {
    margin: 0;
  }

  .switch-label {
    font-size: 14px!important;
  }

  .switches >>> .v-input--selection-controls__input {
    margin-right: 0;
  }

  hr {
    background-color: #e2e2e2
  }

  .header-row {
  }

  .header-column {
    max-width: 150px;
    vertical-align: top;
  }

  .header-column-text {
    /* writing-mode:vertical-rl;
    transform:rotate(180deg) perspective(0);

    height: 100%;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block; */

    /* bottom: 0;
    margin: 0 0 24px 12px;
    padding: 0;
    text-align: left;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    white-space: nowrap; */
  }

  .checkbox-icon-true {
    color: var(--color-blue)!important;
  }

  .checkbox-icon-false {
    color: var(--color-blue)!important;
    opacity: 0.3;
  }
</style>
