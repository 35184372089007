const TRANSPARENCY = Object.freeze({
  HIDDEN: 0,
  NON_ANONYMOUS: 1,
  ANONYMOUS: 2
})

const POLL_TYPES = Object.freeze({
  OPEN_POLL: 0,
  QUICK_POLL: 1
})

const POLL_TYPE_NAMES = Object.freeze({
  0: 'Open Poll',
  1: 'Quick Poll'
})

export {
  TRANSPARENCY,
  POLL_TYPES,
  POLL_TYPE_NAMES
}
