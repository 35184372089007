
<template>
  <PollHistory
    v-if="loggedIn"
  >
  
  </PollHistory>
  <div v-else style="height: 100%;">
    <v-row style="height: 100%; align-items: stretch;">
      <v-col md=12 style="background: #f2f8ff; display: flex; align-items: center; justify-content: center;">
        <div class="text-center">
          <img src="/static/upvote-logo.png" style="max-height: 200px; width: auto;"/>
          <div>
            <br/>
            <h1>Hi, there 👋</h1>
            <h1>Welcome to Upvote Dashboard</h1>
            <br/>
            <br/>
            <h2>Please sign in to get started</h2>
            <br/>
              <a :href="authUrl">
                <img alt="'Sign in with Slack'" height="40" width="172" src="https://platform.slack-edge.com/img/sign_in_with_slack.png" srcset="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x" />
              </a>
            </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import { Api } from '@/api/Api'
import PollHistory from './PollHistory.vue'

export default {
  name: 'Home',
  components: {
    PollHistory
  },
  props: {
  },
  data () {
    return {
      Slack: { 'signInUrl': process.env.VUE_APP_SLACK_SIGN_IN_URL },
      authUrl: null
    }
  },
  computed: {
    loggedIn: function () {
      return this.$store.state.loggedIn
    }
  },
  watch: {
  },
  methods: {
    fetchAuthUrl() {
      this.$store.state.appBarProgressActive = true

      let nextUrl = this.$route.query.nextUrl
      
      axios(
        Api.baseUrl + '/auth/get-auth-url',
        {
          method: 'post',
          data: { next: nextUrl },
          withCredentials: true
        }
      ).then((response) => {
        this.authUrl = response.data
        console.log(response.data)
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
  },
  mounted: function () {
    if (!this.loggedIn) {
      this.fetchAuthUrl()
    }
  },
  filters: {
  }
}
</script>

<style scoped>
</style>
