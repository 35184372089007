<template>
  <v-container
    fluid style="width: 90%;"
  >
    <div v-if="poll != null" class="text-left">
      <v-row v-for="(item, idx) in itemsSorted" :key="idx">
        <v-col cols=1 md=1 class="text-center">
          <v-icon class="upvote-icon">mdi-triangle</v-icon> <div class="upvote-number">{{item.vote_count}}</div>
        </v-col>
        <v-col cols=11 md=11>
          <div class="item-title">
            {{ expandToEmoji(item.title) }}
              <div class="voter-chip" v-if="showWhoVoted && (poll.poll_type == POLL_TYPES.OPEN_POLL) && (poll.transparency != TRANSPARENCY.ANONYMOUS)">
                by&nbsp; 
                <img class="user-icon mr-1" :src="item.creator.profile.image_32"/>
                {{ item.creator.profile.real_name }}
              </div>
          </div>
          <div class="item-description" v-if="item.description != null">
            <p>{{ item.description }}</p>
          </div>

          <div class="item-vote-percentage">
            <v-progress-linear
              :value="item.votePercentage"
              height='20px'
              color="var(--color-light-blue)"
              background-color="#f5f5f5"
            >
              <span class="vote-percentage">
                {{ item.votePercentage | numeral('0') }}%
              </span>
            </v-progress-linear>
          </div>
          <div v-if="showWhoVoted" class="item-voters mt-1">
            <div v-if="showNames">
              <div v-for="(voter, voter_idx) in item.voters" :key="voter_idx" class="voter-chip">
                <img class="user-icon mr-1" :src="voter.profile.image_32"/>
                {{ voter.profile.real_name }}
              </div>
            </div>
            <div v-else>
              <div v-for="(voter, voter_idx) in item.voters" :key="voter_idx" class="d-inline-block">
                <img class="user-icon mr-1" :src="voter.profile.image_32"/>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <hr class="mt-10 mb-5"/>
      <v-row class='my-0'>
        <v-col md=6 class="d-flex my-0 py-0 align-items-center">
          <div class="pt-1 average">
            <div>
              Total votes: {{ totalVotes | numeral("0") }}
            </div>
            <div v-if="numericalOptions === true">
              Average response: {{ averageResponse | numeral("0.00") }}
            </div>
            <div class="mt-2">
              <v-btn
                :color="orderByUpvotes? 'success' : 'default'"
                small
                depressed
                @click="orderByUpvotes ^= true"
                width="160"
              >
                <v-icon>mdi-sort-variant</v-icon><v-spacer></v-spacer>Sort by upvotes
              </v-btn>
            </div>
            <div class="mt-2">
              <div v-if="$store.state.userData.subscription_plan.export_to_csv === true">
                <v-btn small depressed width="160" v-if="poll.series == null" :href="`${Api.baseUrl}/export-xlsx/${ poll.id }`" target="_blank"><v-icon>mdi-file-table-outline</v-icon><v-spacer></v-spacer>Export to .xlsx</v-btn>
                <v-menu top offset-y v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                      v-on="on"
                      v-bind="attrs"
                      small depressed width="160"
                    >
                      <v-icon>mdi-file-table-outline</v-icon><v-spacer></v-spacer>Export to .xlsx
                    </v-btn>
                  </template>
                     <v-list>
                      <v-list-item :href="`${Api.baseUrl}/export-xlsx/${ poll.id }`" target="_blank">
                        <v-list-item-title>Export this poll ({{ poll.create_time |  moment("MMMM DD, YYYY") }})</v-list-item-title>
                      </v-list-item>
                      <v-list-item :href="`${Api.baseUrl}/export-series-xlsx/${ poll.id }`" target="_blank">
                        <v-list-item-title>Export all polls in the series</v-list-item-title>
                      </v-list-item>
                    </v-list>
                </v-menu>
              </div>
              <v-btn small depressed width="160" v-else @click="exportDialogVisible ^= 1"><v-icon>mdi-file-table-outline</v-icon><v-spacer></v-spacer>Export to .xlsx</v-btn>
              <!-- <v-btn small depressed width="160" v-if="$store.state.userData.subscription_plan.export_to_csv === true" :href="`${Api.baseUrl}/export-series-xlsx/${ poll.id }`" target="_blank"><v-icon>mdi-file-table-outline</v-icon><v-spacer></v-spacer>Export series to .xlsx</v-btn> -->
            </div>
          </div>
        </v-col>
        <v-col md=6 class="text-right">
          <div class="d-flex">
            <v-spacer></v-spacer>
            <v-switch
              v-if="poll.transparency != TRANSPARENCY.ANONYMOUS"
              class="switches"
              v-model="showWhoVoted"
              inset
              dense
            >
              <template v-slot:label>
                  <span class="switch-label">Show who voted</span>
              </template>
            </v-switch>
            <v-switch
              v-if="showWhoVoted && poll.transparency != TRANSPARENCY.ANONYMOUS"
              class="switches ml-3"
              v-model="showNames"
              inset
              dense
            >
              <template v-slot:label>
                  <span class="switch-label">Show names</span>
              </template>
            </v-switch>
          </div>
          <div v-if="poll.transparency == TRANSPARENCY.ANONYMOUS" class="anonymous-marker">🥸 Anonymous</div>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="exportDialogVisible"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Action unavailable
        </v-card-title>
        <v-card-text style="color: black;">
          Export is not available on your current plan.
          Please upgrade to one of the paid plans that include exporting polls.
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="exportDialogVisible=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { POLL_TYPE_NAMES, POLL_TYPES, TRANSPARENCY } from '../../Constants.js'
import axios from 'axios'
import { Api } from '@/api/Api'
import { expandToEmoji } from '../../Emoji'

export default {
  name: 'PollViewDefault',
  components: {
  },
  props: {
    poll: Object
  },
  data () {
    return {
      POLL_TYPE_NAMES: POLL_TYPE_NAMES,
      POLL_TYPES: POLL_TYPES,
      TRANSPARENCY: TRANSPARENCY,
      Api: Api,
      expandToEmoji: expandToEmoji,
      totalVotes: null,

      itemsSorted: null,
      showWhoVoted: true,
      showNames: true,
      numericalOptions: false,
      averageResponse: null,


      exportDialogVisible: false,

      orderByUpvotes: null
    }
  },
  computed: {
  },
  watch: {
    $route(to, from) {
    },
    poll(lto, from) {
      this.setup()
    },
    orderByUpvotes(curr, prev) {
      if (curr == true) {
        this.itemsSorted.sort((a, b) => {
          if (a.vote_count > b.vote_count) {
            return -1
          } else if (a.vote_count < b.vote_count) {
            return 1
          } else {
            return 0
          }
        })
      } else {
        this.itemsSorted = []
        for(const item of this.poll.items) {
          this.itemsSorted.push(item)
        }
      }
    }
  },
  methods: {
    setup() {
      this.totalVotes = 0
      for (const item of this.poll.items){
        this.totalVotes += item.vote_count
      }

      for(const item of this.poll.items) {
        if (this.totalVotes > 0){
          item.votePercentage = item.vote_count / this.totalVotes * 100
        } else {
          item.votePercentage = 0
        }
      } 

      var allNum = true
      let voteSum = 0
      for(const item of this.poll.items) {
        let numValue = Number(item.title.replace(',', '.'))
        if ( !Number.isNaN(numValue)) {
          voteSum += numValue * item.vote_count
        } else {
          allNum = false
          break
        }
      }
      if (allNum) {
        this.averageResponse = voteSum / this.totalVotes
        this.numericalOptions = true
      }
      this.itemsSorted = []
      for(const item of this.poll.items) {
        this.itemsSorted.push(item)
      }

      if (this.poll.poll_type == POLL_TYPES.OPEN_POLL) {
        this.orderByUpvotes = true
      } else {
        this.orderByUpvotes = false
      }
    }
  },
  created: function () {
    this.setup()
  },
}
</script>

<style scoped>

</style>

<style scoped>

  .poll-title-link {
    color: inherit;
    text-decoration: none;
  }

  .poll-title-link:hover {
    color: var(--color-blue);
  }

  .upvote-icon {
    color: var(--color-votum-red)!important;
    font-size: 19px!important;
    opacity: 0.8;
  }

  .upvote-number {
    font-size: 18px!important;
    font-weight: bold;
    opacity: 0.9;
  }

  .item-title {
    font-size: 18px!important;
    font-weight: 400;
  }

  .item-description {
    color:#606060;  
  }

  .v-progress-linear {
    border-radius: 3px;
  }

  .vote-percentage {
    font-size: 12px;
    font-weight: 600;
  }

  .voter-chip {
    display: inline-block;
    margin: 1px;
    padding: 2px 5px;
    background: rgb(245, 245, 245);
    border-radius: 3px;
    font-size: 12px;
  }
  .voter-chip img {
    vertical-align: text-bottom;
  }

  .switches {
    margin: 0;
  }

  .switch-label {
    font-size: 14px!important;
  }

  .switches >>> .v-input--selection-controls__input {
    margin-right: 0;
  }

  hr {
    background-color: #e2e2e2
  }

  .average {
    font-weight: 500;
  }

  .anonymous-marker {
    align-self: flex-start;
    display: inline-block;
    margin: 1px;
    padding: 3px 7px;
    background: rgb(245, 245, 245);
    border-radius: 3px;
    font-size: 15px;
  }


</style>
