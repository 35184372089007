<template>
  <div
    v-if="$store.state.loggedIn === true"
    id="app"
  >
    <v-app>
      <v-app-bar
        app
        flat
        clipped-left
        style="background-color: white; box-shadow: 0 1px 4px rgb(0 0 0 / 15%)!important;"
        v-if="!$store.state.appBarOverride"
      >
        <router-link
          class="site-name" to="/" tag="span" style="cursor: pointer"
        >
          <a class="header-link">
            <img style="height: 40px; border-radius: 5px; margin-top: 7px;" src='/static/upvote-logo.png'/>
          </a>
        </router-link>
        <div style="display: inline-block; font-weight: 600; font-size: 20px; color: inherit; text-decoration: none;" class="ml-0 pb-0">
          <router-link to="/" style="color: inherit; text-decoration: none;">
            Upvote
          </router-link>
        </div>
        
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn to="/" text><v-icon>mdi-home</v-icon>&nbsp;Home</v-btn>
          <v-btn v-if="$store.state.userData.is_admin == true" to="/admin-plans" text><v-icon>mdi-rocket-launch</v-icon>&nbsp;Subscription</v-btn>
          <v-btn v-if="$store.state.userData.is_admin == true" to="/admin-settings" text><v-icon>mdi-account-key</v-icon>&nbsp;Settings</v-btn>
        </v-toolbar-items>
        <v-menu
          v-if="loggedIn"
          offset-y
          bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                class="article-menu-button"
              >
                <img class="user-icon" :src="$store.state.userData.profile.image_48"/>
                <span
                  v-if="!$vuetify.breakpoint.mobile"
                >
                  <!-- &nbsp;{{ $store.state.userData.name }} -->
                </span>
              </v-btn>
            </template>
          <v-list dense>
            <v-list-item style=" font-weight: bold;">
              @{{$store.state.userData.profile.display_name}} | {{$store.state.userData.team_name}}
            </v-list-item>
            <v-list-item
              @click="logout(this)"
              v-if="loggedIn"
            >
              <v-list-item-icon><v-icon>mdi-exit-to-app</v-icon></v-list-item-icon>
              <v-list-item-title>Sign out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-progress-linear
          :active="$store.state.appBarProgressActive"
          :indeterminate="$store.state.appBarProgressActive"
          absolute
          bottom
          color="var(--color-votum-red)"
        ></v-progress-linear> 
      </v-app-bar>
      <v-main>
        <router-view></router-view>
      </v-main>
  </v-app>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import NavigationDrawer from '../components/NavigationDrawer.vue'

export default {
  components: {
  },
  data: function () {
    return {
      showDrawer: false,
      searchBoxVisible: false
    }
  },
  computed: {
    loggedIn: function () {
      return this.$store.state.loggedIn
    },
  },
  methods: {
    ...mapActions({
      checkLoggedIn: 'checkLoggedIn',
      logoutAction: 'logout'
    }),
    logout () {
      this.logoutAction(this)
        .then(() => {
          this.$router.push('/')
          location.reload()
        })
    },
  },
  mounted () {
    if (!this.$vuetify.breakpoint.mobile) {
      this.showDrawer = true
    }
    this.checkLoggedIn().catch(()=>{})
  }
}
</script>

<style scoped>
  .footer {
    text-align: center;
    width: 100%;
  }

  .footer a {
    text-decoration: none;
    color: black!important;
    font-size: 12px;
  }

  .footer a:visited {
    color: black!important;
  }

  .search-box {
    margin-left: auto;
    margin-right: auto;
  }

  a.header-link {
    text-decoration: none;
    color: black!important;
  }

  a.header-link:visited {
    text-decoration: none;
    color: black!important;
  }

  a.header-link-right {
    font-size: 16px;
    font-weight: 400;
  }

  a.header-link .v-icon {
    font-size: 36px!important;
  }

  img.user-icon {
    border-radius: 50%;
    height: 40px;
  }

</style>

<style>
  .main-container {
      /* min-height: 100vh; */
      text-align: center;
      max-width: 600px!important;
      width: 95%!important;
  }

  .user-chip {
    background-color: rgb(247, 247, 247);
    display: inline-block;
    padding: 3px 5px;
    border-radius: 3px;
  }

  .user-icon, .user-chip img {
    height: 18px;
    object-fit: cover;
    border-radius: 3px;
    vertical-align: text-bottom;
  }

  .anon-emoji {
    font-size: 18px;
    vertical-align: middle;
  }

  hr {
    height: 1px!important;
    background-color: lightgray;
    border: none;
  }

  .feedback-card {
    text-align: left;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-top: 20px;
  }
</style>
