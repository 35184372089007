import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import AdminSettings from './views/AdminSettings.vue'
import AdminPlans from './views/AdminPlans.vue'
import ShowPoll from './views/ShowPoll.vue'
import store from '@/store/index'

Vue.use(Router)
let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/show-poll/:pollId',
      name: 'show-poll',
      component: ShowPoll,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin-settings',
      name: 'admin-settings',
      component: AdminSettings,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin-plans',
      name: 'admin-plans',
      component: AdminPlans,
      meta: {
        requiresAuth: true,
        requiresAdmin: true
      }
    },
  ]
})

let checkRoute = function (to, from, next) {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.loggedIn === false) {
      next({
        path: '/',
        query: { nextUrl: to.fullPath }
      })
    } else {
      if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (store.state.userData.is_admin == true) {
          next()
        } else {
          next({ name: 'home' })
        }
      } else {
        next()
      }
    }
  } else {
    next()
  }
}

router.beforeEach((to, from, next) => {
  if (store.state.loggedIn == undefined) {
    store.dispatch('checkLoggedIn').then(() => {
    })
    .catch(() => {})
    .finally(() => {
      checkRoute(to, from, next)
    })
  } else {
    checkRoute(to, from, next)
  }
})

export default router
