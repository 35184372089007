<template>
  <v-dialog
    v-model="dialogVisible"
    max-width="600px"
    overlay-opacity="0.8"
    scrollable
    persistent
  >
    <v-card>
      <v-progress-linear
        :active="$store.state.appBarProgressActive"
        :indeterminate="$store.state.appBarProgressActive"
        absolute
        color="orange"
      ></v-progress-linear> 
      <v-card-title>
        Payment details 
        <v-spacer/>
        <a
          class="back-button"
          @click="dialogVisible = false"
        ><v-icon>mdi-close</v-icon>
        </a>
      </v-card-title>
      <v-card-text>
        <div style="color: black; font-size: 16px;" v-if="planInfo != null">
          <b>Plan: </b>{{planInfo.name}}<br/>
          <b>Price: </b>${{planInfo.price | numeral("0.00")}} {{ billingPeriod == 'monthly' ? 'every 30 days' : 'per year' }}
        </div>
        <div class="checkout-container"></div>
      </v-card-text>
      <v-card-actions class="justify-end mr-2 mb-3" >
        <!-- <v-btn
          color="primary"
          @click="$emit('paymentInfoAdded')"
          >Confirm and pay</v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import { Api } from '@/api/Api'

export default {
  name: 'PaymentDetailsDialog',
  components: {
  },
  props: {
    showDialogToggle: {
      type: Boolean,
      default: false
    },
    paddleProductId: Number,
    planInfo: Object,
    billingPeriod: String
  },
  data () {
    return {
      dialogVisible: false,
    }
  },
  computed: {
  },
  watch: {
    showDialogToggle (oldVal, newVal) {
      this.dialogVisible = !this.dialogVisible
      this.showError = false
    },
    dialogVisible (newVal, oldVal) {
      if (oldVal == false && newVal == true) {
        this.$store.state.appBarProgressActive = true
        setTimeout(() => {
          this.initPaddle()
          this.$store.state.appBarProgressActive = false
        }, 300)
      }
    },
  },
  methods: {
    initPaddle () {
      Paddle.Setup({
        vendor: 35700
      });

      // Paddle.Environment.set('sandbox');
      // Paddle.Setup({
      //   vendor: 2361 // sandbox
      // });

      Paddle.Checkout.open({
        method: 'inline',
        product: this.paddleProductId,       // Replace with your Product or Plan ID
        passthrough: {slack_team_id: this.$store.state.userData.team_id, slack_user_id: this.$store.state.userData.id},
        allowQuantity: false,
        disableLogout: true,
        frameTarget: 'checkout-container', // The className of your checkout <div>
        frameInitialHeight: 416,
        frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;',
        successCallback: this.paymentDone
      });
    },
    paymentDone (data) {
      this.$emit('paymentDone')  
    }
  },
  mounted: function () {
  },
  filters: {
  }
}
</script>

<style scoped>
  .user-chip {
    background-color: rgb(245, 245, 245);
    display: inline-block;
    padding: 5px 6px;
    border-radius: 5px;
  }

  .user-icon {
    height: 18px;
    border-radius: 3px;
    vertical-align: bottom;
  }

  a.back-button {
    font-size: 12px;
  }
</style>

