<template>
  <div
    v-if="$store.state.loggedIn === false"
    id="app"
  >
    <v-app>
      <v-main>
          <router-view></router-view>
      </v-main>
  </v-app>
  </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  data: function () {
    return {
      showDrawer: false,
      searchBoxVisible: false
    }
  },
  computed: {
    loggedIn: function () {
      return this.$store.state.loggedIn
    },
    loginDialogToggle: function () {
      return true
    }
  },
  methods: {
    ...mapActions({
      checkLoggedIn: 'checkLoggedIn',
      logoutAction: 'logout',
    }),
    logout () {
      this.logoutAction(this)
        .then(() => {
          this.$router.push('/')
          location.reload()
        })
    },
  },
  components: {
  },
  mounted () {
    // this.checkLoggedIn()
  }
}
</script>

<style>
  .blogboard-red {
    color: #fd3a5c;
  }

  .footer {
    text-align: center;
    width: 100%;
  }

  .footer a {
    text-decoration: none;
    color: black!important;
    font-size: 12px;
  }

  .footer a:visited {
    color: black!important;
  }

  .search-box {
    margin-left: auto;
    margin-right: auto;
  }

  a.header-link {
    text-decoration: none;
    color: black!important;
  }

  a.header-link:visited {
    text-decoration: none;
    color: black!important;
  }

  a.header-link-right {
    font-size: 16px;
    font-weight: 400;
  }

  a.header-link .v-icon {
    font-size: 36px!important;
  }

</style>
