import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@mdi/font/css/materialdesignicons.min.css";
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import VueMeta from 'vue-meta'

Vue.use(Vuetify, {
  iconfont: 'mdi'
})

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(require('vue-moment'));

// var locale = require('numeral/locales/en-gb');
// numeral.locale('en-gb');

Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });

new Vue({
  router,
  store,
  render: h => h(App),
  vuetify: new Vuetify({
    icons: {
      iconfont: 'md', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: { dark: false },
  })
}).$mount('#app')
