import * as Util from './Util.js'
import * as ColorSchemes from '../../../ColorSchemes'
import { expandToEmoji } from '../../../Emoji'

const setupTrendAverage = function (self) {
  let data = {
    labels: [],
    datasets: [
      {
        label: 'Average score',
        data: [],
        backgroundColor: Util.addAlpha(ColorSchemes.ClassicMedium10[0], 1),
        borderColor: Util.addAlpha(ColorSchemes.ClassicMedium10[0], 1),
        borderWidth: 2,
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      }
    ]
  };  
 
  var allNum = true
  for(const item of self.series[0].items) {
    let numValue = Number(item.title.replace(',', '.'))
    if ( Number.isNaN(numValue)) {
      allNum = false
      break
    }
  }
  let itemValues = [] 
  if (allNum) {
    for(const item of self.series[0].items) {
      itemValues.push(Number(item.title.replace(',', '.')))
    }
  } else {
    for(const [idx, item] of self.series[0].items.entries()) {
      itemValues.push(self.series[0].items.length - idx - 1)
    }
  }

  let maxY = null
  let minY = null
  for (const poll of self.series) {
    data.labels.push(poll.create_time)
    let sum = 0
    let cnt = 0
    for(const [idx, item] of poll.items.entries()) {
      sum += item.vote_count * itemValues[idx]
      cnt += item.vote_count
      maxY = maxY == null ? itemValues[idx] : Math.max(itemValues[idx], maxY)
    }
    if (cnt > 0) {
      let val = sum / cnt
      data.datasets[0].data.push(val)
      minY = minY == null ? val : Math.min(minY, val)
    } else {
      data.datasets[0].data.push(null)
    }
  }

  let config = {
    type: 'line',
    data: data,
    options: {
      responsive: true,
      scales: {
          x: {
              type: 'time',
              time: {
                  unit: 'day'
              },
              grid: {
                display: false,
                drawBorder: true,
                drawOnChartArea: true,
                drawTicks: false,
              }
          },
          y: {
            display: true,
            suggestedMin: Math.min(0, minY),
            suggestedMax: maxY
          }
      },
    },
  }
  if (!allNum) {
    config.options.scales.y = {
        display: true,
        ticks: {
          stepSize: 1,
          callback: function(value, index, values) {
            if (value >= 0 && value < self.series[0].items.length) {
              let maxInd = self.series[0].items.length - 1
              return expandToEmoji(self.series[0].items[maxInd - value].title)
            } else {
              return ''
            }
          }
        },
        min: 0,
        max: self.series[0].items.length - 1
    }
  }
  return config
}

export { setupTrendAverage }
