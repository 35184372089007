<template>
  <v-container
    fluid style="max-width: 1100px;"
  >
    <div v-if="poll != null && poll.poll_type == POLL_TYPES.QUICK_POLL" class="text-left mb-10">
      <div style="" class="d-flex w-100 mb-10">
        <v-spacer></v-spacer>
        <v-select
          :items="chartTypeItems"
          item-text="text"
          item-value="value"
          v-model="selectedChartType"
          label="Metric"
          dense
          style="max-width: 300px"
        ></v-select>
      </div>
      <canvas id="series-div"></canvas>
    </div>
    <div v-if="poll != null && poll.poll_type == POLL_TYPES.OPEN_POLL">
      Can't show trends for Open Polls.
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { POLL_TYPE_NAMES, POLL_TYPES, TRANSPARENCY } from '../../Constants.js'
import axios from 'axios'
import { Api } from '@/api/Api'
import { expandToEmoji } from '../../Emoji'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import 'chartjs-adapter-moment'
import { setupTrendAverage } from './TrendChart/TrendAverage.js'
import { setupTrendByOption } from './TrendChart/TrendByOption.js'
import { setupTrendTotalVotes } from './TrendChart/TrendTotalVotes.js'
import { setupPercentByOption } from './TrendChart/PercentByOption.js'


export default {
  name: 'PollViewSeries',
  components: {
  },
  props: {
    poll: Object
  },
  data () {
    return {
      POLL_TYPE_NAMES: POLL_TYPE_NAMES,
      POLL_TYPES: POLL_TYPES,
      TRANSPARENCY: TRANSPARENCY,
      expandToEmoji: expandToEmoji,
      totalVotes: 0,

      series: null,

      chartTypeItems: [
        { text: 'Average Score', value: 'avg_score' },
        { text: 'Total Votes', value: 'total_votes' },
        { text: 'Votes per Choice', value: 'votes_per_choice' },
        { text: '% of Votes per Choice', value: 'percent_per_choice' },
      ],
      selectedChartType: 'avg_score',
      chart: null
    }
  },
  computed: {
  },
  watch: {
    $route(to, from) {
    },
    selectedChartType(to, from) {
      this.redrawChart()
    },
  },
  methods: {
    fetchSeries() {
      let requestData = {
        poll_id: this.poll.id
      }
      this.$store.state.appBarProgressActive = true
      return new Promise((resolve, reject) => {
        axios(
          Api.baseUrl + '/get-series',
          {
            method: 'post',
            data: requestData,
            withCredentials: true
          }
        ).then((response) => {
          this.series = response.data
          resolve(response.data)
        }).catch((error) => {
          console.log(error)
          reject(error)
        }).finally(() => {
          this.$store.state.appBarProgressActive = false
        })
      })
    },
    redrawChart () {
      let ctx = document.getElementById('series-div');
      let config = null
      if (this.selectedChartType == 'avg_score') {
        config = setupTrendAverage(this)
      } else if (this.selectedChartType == 'total_votes') {
        config = setupTrendTotalVotes(this)
      } else if (this.selectedChartType == 'votes_per_choice') {
        config = setupTrendByOption(this)
      } else if (this.selectedChartType == 'percent_per_choice') {
        config = setupPercentByOption(this)
      }
      config.options = Object.assign(
        config.options,
        {
          plugins: {
            legend: {
              position: 'bottom',
              align: 'end'
            }
          }
        }
      )
      if (this.chart != null) {
        this.chart.destroy()
      }
      this.chart = new Chart(ctx, config);
    }
  },
  mounted: function () {
    this.fetchSeries().then(() => {
      this.redrawChart()
    })
  },
  filters: {
  }
}
</script>

<style scoped>

</style>

<style scoped>

  .poll-title-link {
    color: inherit;
    text-decoration: none;
  }

  .poll-title-link:hover {
    color: var(--color-blue);
  }

  .upvote-icon {
    color: var(--color-votum-red)!important;
    font-size: 19px!important;
    opacity: 0.8;
  }

  .upvote-number {
    font-size: 18px!important;
    font-weight: bold;
    opacity: 0.9;
  }

  .item-title {
    font-size: 18px!important;
    font-weight: 500;
  }

  .v-progress-linear {
    border-radius: 3px;
  }

  .vote-percentage {
    font-size: 12px;
    font-weight: 600;
  }

  .voter-chip {
    display: inline-block;
    margin: 1px;
    padding: 2px 5px;
    /* background: rgb(245, 245, 245); */
    border-radius: 3px;
    font-size: 12px;
  }
  .voter-chip img {
    vertical-align: text-bottom;
  }

  .switches {
    margin: 0;
  }

  .switch-label {
    font-size: 14px!important;
  }

  .switches >>> .v-input--selection-controls__input {
    margin-right: 0;
  }

  hr {
    background-color: #e2e2e2
  }

  .header-row {
  }

  .header-column {
    max-width: 150px;
    vertical-align: top;
  }

  .header-column-text {
    /* writing-mode:vertical-rl;
    transform:rotate(180deg) perspective(0);

    height: 100%;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block; */

    /* bottom: 0;
    margin: 0 0 24px 12px;
    padding: 0;
    text-align: left;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    white-space: nowrap; */
  }

  .checkbox-icon-true {
    color: var(--color-blue)!important;
  }

  .checkbox-icon-false {
    color: var(--color-blue)!important;
    opacity: 0.3;
  }
</style>
