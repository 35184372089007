import * as Util from './Util.js'
import * as ColorSchemes from '../../../ColorSchemes'
import { expandToEmoji } from '../../../Emoji'

const setupTrendByOption = function (self) {
  let data = {
    labels: [],
    datasets: []
  };  

  for (const [idx, item] of self.series[0].items.entries()) {
    data.datasets.push(
      {
        label: expandToEmoji(item.title),
        data: [],
        backgroundColor: Util.addAlpha(ColorSchemes.ClassicMedium10[idx%10], 0.95),
        borderColor: Util.addAlpha(ColorSchemes.ClassicMedium10[idx%10], 0.95),
        borderWidth: 2,
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      }
    )
  }
  
  let maxY = 0
  for (const poll of self.series) {
    data.labels.push(poll.create_time)
    for(const [idx, item] of poll.items.entries()) {
      data.datasets[idx].data.push(item.vote_count)
      maxY = Math.max(maxY, item.vote_count)
    }
  }

  let config = {
    type: 'line',
    data: data,
    options: {
      responsive: true,
      scales: {
          x: {
              type: 'time',
              time: {
                  unit: 'day'
              },
              grid: {
                display: false,
                drawBorder: true,
                drawOnChartArea: true,
                drawTicks: false,
              }
          },
          y: {
              display: true,
              title: {
                display: true,
                text: 'Upvotes'
              },
              suggestedMin: 0,
              suggestedMax: maxY 
          }
      },
      plugins: {
        legend: {
          position: 'bottom',
        },
      }
    },
  }
  return config
}

export { setupTrendByOption }
