import * as Util from './Util.js'
import * as ColorSchemes from '../../../ColorSchemes'
import { expandToEmoji } from '../../../Emoji'

const setupTrendTotalVotes = function (self) {
  let data = {
    labels: [],
    datasets: [
      {
        label: 'Total upvotes',
        data: [],
        backgroundColor: Util.addAlpha(ColorSchemes.ClassicMedium10[1], 1),
        borderColor: Util.addAlpha(ColorSchemes.ClassicMedium10[1], 1),
        borderWidth: 2,
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      }
    ]
  };  
  
  let maxY = 0
  for (const poll of self.series) {
    data.labels.push(poll.create_time)
    let cnt = 0
    for(const [idx, item] of poll.items.entries()) {
      cnt += item.vote_count
    }
    data.datasets[0].data.push(cnt)
    maxY = Math.max(maxY, cnt)
  }

  let config = {
    type: 'line',
    data: data,
    options: {
      responsive: true,
      scales: {
          x: {
              type: 'time',
              time: {
                  unit: 'day'
              },
              grid: {
                display: false,
                drawBorder: true,
                drawOnChartArea: true,
                drawTicks: false,
              }
          },
          y: {
              display: true,
              min: 0,
              suggestedMax: maxY + 1
          }
      },
    },
  }
  return config
}

export { setupTrendTotalVotes }
