let emoji_name_map = require("emoji-name-map");

var expandToEmoji = function (text) {
  let matches = text.match(/:.+?:/g)

  if (matches != null) {
    matches.forEach(match => {
      let emoji = emoji_name_map.get(match)
      if (emoji != undefined && emoji != '' && emoji != null) {
        text = text.replace(match, emoji)
      }
    });
  }

  return text
}

export { expandToEmoji }
